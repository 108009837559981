import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Fetch_Url } from "../Context/Context";
import axios from "axios";
import { Howl } from "howler";
import audio from "../Home/ringtone-193209.mp3";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Home() {
  const navigate = useNavigate();
  const prevDataLengthRef = useRef(0);

  const [groundinfo, setGroundinfo] = useState([]);
  const [todayearn, setTodayearn] = useState([]);
  const [totalorder, setTotalorder] = useState([]);
  const [totalexpense, setTotalexpense] = useState([]);
  const [totalparty, setTotalparty] = useState([]);
  const [orderdata, setOrderdata] = useState([]);
  const [kotinfo, setKotinfo] = useState([]);
  const [selectman, setSelectman] = useState([]);
  const [mandata, setMandata] = useState([]);

  window.$loginInfo = JSON.parse(localStorage.getItem("loginData"));
  // console.log(window.$loginInfo, "loginInfo");

  const getGroundTable = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_fetch_table",
        floor_id: "1",
      })
      .then((resp) => {
        console.log(resp, "resp getGroundTable data");
        setGroundinfo(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEarnInfo = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_bill_fetch_today",
      })
      .then((resp) => {
        console.log(resp, "resp getEarnInfo data");
        setTodayearn(resp?.data?.grand_total);
        setTotalorder(resp?.data?.total_orders);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getExpense = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_fetch_expenses",
      })
      .then((resp) => {
        console.log(resp, "resp getExpense data");
        setTotalexpense(resp?.data?.total_expense_amount_of_current_month);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getParty = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_fetch_party",
      })
      .then((resp) => {
        console.log(resp, "resp getParty data");
        setTotalparty(resp?.data?.total_party_amount);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getServiceman = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_fetch_staff",
      })
      .then((resp) => {
        console.log(resp, "resp getServiceman data");
        setMandata(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOrders = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "fetch_customer_cart_for_admin",
      })
      .then((resp) => {
        console.log(resp, "resp getOrders data");
        setOrderdata(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getKOTInfo = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_kitechnlist",
        kot_request: "Maked",
      })
      .then((resp) => {
        console.log(resp, "resp getKOTInfo data");
        setKotinfo(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateKOT = (itemId) => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_update_cart_bykiten",
        id: itemId,
        kot_request: "delivered",
      })
      .then((resp) => {
        console.log(resp, "resp updateKOT data");
        resp?.data?.success && getKOTInfo();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateOrders = (tableId, status) => {
    selectman?.length > 0
      ? axios
          .post(`${Fetch_Url}/restaurant_api.php`, {
            aksi: "accept_cart_by_manager",
            TableId: tableId,
            OrderStatus: status,
            ServedBy: selectman,
          })
          .then((resp) => {
            console.log(resp, "resp updateOrders data");
            resp?.data?.success && getOrders();
            getGroundTable();
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Please Select Serviceman", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  // const getBaseTable = () => {
  //   axios
  //     .post(`${Fetch_Url}/restaurant_api.php`, {
  //       aksi: "proses_fetch_table",
  //       floor_id: "2",
  //     })
  //     .then((resp) => {
  //       console.log(resp, "resp getBaseTable data");
  //       setBaseinfo(resp?.data?.result);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const getHallTable = () => {
  //   axios
  //     .post(`${Fetch_Url}/restaurant_api.php`, {
  //       aksi: "proses_fetch_table",
  //       floor_id: "3",
  //     })
  //     .then((resp) => {
  //       console.log(resp, "resp getHallTable data");
  //       setHallinfo(resp?.data?.result);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const playNotificationSound = () => {
    const sound = new Howl({
      src: [audio],
    });
    sound.play();
  };

  useEffect(() => {
    if (orderdata?.length > prevDataLengthRef?.current) {
      playNotificationSound();
    }
    prevDataLengthRef.current = orderdata?.length;
  }, [orderdata]);

  useEffect(() => {
    if (!localStorage.getItem("loginData")) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    getGroundTable();
    getEarnInfo();
    getServiceman();
    getExpense();
    getParty();
    getOrders();
  }, []);

  setTimeout(() => {
    getOrders();
  }, 10000);

  setTimeout(() => {
    getKOTInfo();
  }, 10000);

  return (
    <>
      <ToastContainer />
      <div className="foodImg">
        <img src="http://bullbitescafe.com/its_admin/img/logo2.jpg" alt="" />
      </div>
      <Link to="/earningdetails">
        <div className="foodInfo">
          <h4>
            Today Earning: <i className="fa fa-rupee"></i>
            {todayearn}
          </h4>
          <h4>Total Orders: {totalorder}</h4>
        </div>
      </Link>
      <div className="foodInfo2">
        <Link to="/expense">
          <h4>
            Total Expense: <i className="fa fa-rupee"></i>
            {totalexpense}
          </h4>
        </Link>
        <Link to="/partybook">
          <h4>
            Party Booking: <i className="fa fa-rupee"></i>
            {totalparty}
          </h4>
        </Link>
      </div>
      <div className="tableCont">
        <div className="alltableBox">
          {groundinfo?.map((item) => {
            return (
              <>
                <Link to="/dashboard" state={item}>
                  <div
                    className={
                      item?.status === "booked" ? "bookTable" : "freeTable"
                    }
                  >
                    <h4>{item?.table_no}</h4>
                    <h5>
                      <i className="fa fa-rupee"></i>
                      {item?.tableAmt}&nbsp;({item?.kot_item})
                    </h5>
                  </div>
                </Link>
              </>
            );
          })}
        </div>
        {/* <div className="tableHead">
          <h4>Basement</h4>
        </div>
        <div className="alltableBox">
          {baseinfo?.map((item) => {
            return (
              <>
                <Link to="/dashboard" state={item}>
                  <div className="tableBox">
                    <h4>{item?.table_no}</h4>
                  </div>
                </Link>
              </>
            );
          })}
        </div>
        <div className="tableHead">
          <h4>Party Hall</h4>
        </div>
        <div className="alltableBox">
          {hallinfo?.map((item) => {
            return (
              <>
                <Link to="/dashboard" state={item}>
                  <div className="tableBox">
                    <h4>{item?.table_no}</h4>
                  </div>
                </Link>
              </>
            );
          })}
        </div> */}
      </div>
      <div className="kotFlex">
        <div>
          <div className="kotHead">
            <h3>My Orders</h3>
          </div>
          <div className="kotTable">
            <table>
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th>Customer Mobile</th>
                  <th>Table No.</th>
                  <th>Items</th>
                  <th>Total Quantity</th>
                  <th>Total Price</th>
                  <th>Served By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {orderdata?.map((item) => {
                  return (
                    <>
                      <tr>
                        <td>{item?.CustomerName}</td>
                        <td>{item?.CustomerMobile}</td>
                        <td>{item?.TableNo}</td>
                        <td>{item?.items_ordered}</td>
                        <td>{item?.total_quantity}</td>
                        <td>{item?.total_price}</td>
                        <td>
                          <select
                            onChange={(e) => {
                              setSelectman(e.target.value);
                            }}
                          >
                            <option selected hidden>
                              Serviceman
                            </option>
                            {mandata?.map((item) => {
                              return (
                                <>
                                  <option value={item?.id}>{item?.Name}</option>
                                </>
                              );
                            })}
                          </select>
                        </td>
                        <td>
                          <button
                            onClick={() => {
                              updateOrders(item?.table_id, "Accept");
                            }}
                          >
                            Accept
                          </button>
                          <button
                            onClick={() => {
                              updateOrders(item?.table_id, "Reject");
                            }}
                            style={{ background: "red", color: "white" }}
                          >
                            Reject
                          </button>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div className="kotHead">
            <h3>KOT Orders</h3>
          </div>
          <div className="kotTable">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Table No.</th>
                  <th>Menu Item</th>
                  <th>Quantity</th>
                  <th>KOT Status</th>
                  <th>Date Time</th>
                  <th>Served By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {kotinfo?.map((item) => {
                  return (
                    <>
                      <tr>
                        <td>{item?.srno}</td>
                        <td>{item?.table_id}</td>
                        <td>{item?.menu_id}</td>
                        <td>{item?.quantity}</td>
                        <td>{item?.kot_status}</td>
                        <td>{item?.date_time}</td>
                        <td>{item?.served_by}</td>
                        <td>
                          <button
                            onClick={() => {
                              updateKOT(item?.id);
                            }}
                          >
                            {item?.kot_action}
                          </button>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

/* Home CSS */
