import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./Home/Home";
import Dashboard from "./Components/Dashboard/Dashboard";
import Invoice from "./Components/Invoice/Invoice";
import EarningDetails from "./Components/EarningDetails/EarningDetails";
import KotOrders from "./Components/KotOrders/KotOrders";
import Expense from "./Components/Expense/Expense";
import AllEarning from "./Components/EarningDetails/AllEarning/AllEarning";
import Login from "./Components/Login/Login";
import PartyBook from "./Components/PartyBook/PartyBook";
import CategoryDetails from "./Components/Expense/CategoryDetails/CategoryDetails";
import EarningInvoice from "./Components/EarningDetails/EarningInvoice/EarningInvoice";

function App() {
  window.$loginInfo = JSON.parse(localStorage.getItem("loginData"));
  // console.log(window.$loginInfo, "loginInfo");

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/invoice" element={<Invoice />}></Route>
        <Route path="/earningdetails" element={<EarningDetails />}></Route>
        <Route path="/allearning" element={<AllEarning />}></Route>
        <Route path="/earninginvoice" element={<EarningInvoice />}></Route>
        <Route path="/kotorders" element={<KotOrders />}></Route>
        <Route path="/expense" element={<Expense />}></Route>
        <Route path="/categorydetails" element={<CategoryDetails />}></Route>
        <Route path="/partybook" element={<PartyBook />}></Route>
      </Routes>
    </>
  );
}

export default App;

// logout button
