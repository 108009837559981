import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Fetch_Url } from "../../../Context/Context";

export default function AllEarning() {
  const location = useLocation();
  const stateData = location?.state;
  console.log(stateData, "stateData");

  const navigate = useNavigate();
  const [allinfo, setAllinfo] = useState([]);

  const getAllEarn = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_bill_fetch_by_invoice",
        invoice_id: stateData?.invoice_id,
      })
      .then((resp) => {
        console.log(resp, "resp getAllEarn data");
        setAllinfo(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllEarn();
  }, [stateData]);

  return (
    <>
      <div className="backBtn">
        <i
          className="fa fa-angle-left"
          onClick={() => {
            navigate(-1);
          }}
        ></i>
      </div>
      <div className="earnTable">
        <table>
          <thead>
            <tr>
              <th>Invoice No.</th>
              <th>Table No.</th>
              <th>GSTIN</th>
              <th>Total Amount</th>
              <th>Cabin Charges</th>
              <th>Grand Total</th>
              <th>Time</th>
              <th>Payment Status</th>
              <th>Served By</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{stateData?.invoice_id}</td>
              <td>{stateData?.table_id}</td>
              <td>
                <i className="fa fa-rupee"></i>
                {stateData?.gst}
              </td>
              <td>
                <i className="fa fa-rupee"></i>
                {stateData?.total_amount}
              </td>
              <td>
                <i className="fa fa-rupee"></i>
                {stateData?.cabin_charge}
              </td>
              <td>
                <i className="fa fa-rupee"></i>
                {+stateData?.grand_total + +stateData?.cabin_charge}
              </td>
              <td>{stateData?.check_out_time}</td>
              <td>{stateData?.payment_status}</td>
              <td>{stateData?.ServedBy}</td>
              <td>
                <button
                  onClick={() => {
                    navigate("/earninginvoice", {
                      state: { stateData, allinfo },
                    });
                  }}
                >
                  Print
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="earnTable">
        <table>
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Quantity</th>
              <th>Current Price</th>
              <th>Total Price</th>
            </tr>
          </thead>
          <tbody>
            {allinfo?.map((item) => {
              return (
                <>
                  <tr>
                    <td>{item?.item_name}</td>
                    <td>{item?.quantity}</td>
                    <td>
                      <i className="fa fa-rupee"></i>
                      {item?.current_price}
                    </td>
                    <td>
                      <i className="fa fa-rupee"></i>
                      {item?.total_price}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
