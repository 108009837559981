import { useLocation, useNavigate } from "react-router-dom";

export default function EarningInvoice() {
  const location = useLocation();
  const stateData = location?.state;
  console.log(stateData, "stateData");

  const navigate = useNavigate();

  return (
    <>
      <div className="invoiceCont">
        <div className="invoiceHead">
          <h4>The Bull Bites Cafe</h4>
          <h5>Sector-3, Herbal Park, New Moradabad</h5>
          <h5>
            <strong>Invoice No. {stateData?.stateData?.invoice_id} </strong>
          </h5>
          <h6>GSTIN No. 09HOYPK7457G1ZW</h6>
        </div>
        <div className="invoiceBox">
          <div className="invoiceBot">
            <div className="invoiceData">
              <h4>Table </h4>
              <h4>{stateData?.stateData?.table_id}</h4>
            </div>
            <div className="invoiceData">
              <h4>Served By</h4>
              <h4>{stateData?.stateData?.ServedBy}</h4>
            </div>
          </div>
          <hr />
          {stateData?.allinfo?.map((item) => {
            return (
              <>
                <div className="invoiceTxt">
                  <h5>{item?.item_name}</h5>
                  <h5>
                    {item?.quantity}
                    <i className="fa fa-times"></i>
                    {item?.current_price}
                  </h5>
                  <h5 style={{ textWrap: "nowrap" }}>
                    <i className="fa fa-rupee"></i> {item?.total_price}
                  </h5>
                </div>
              </>
            );
          })}
          <hr />
          <div className="invoiceBot">
            {/* {stateData?.stateData?.cabin !== "" &&
              <div className="invoiceData">
                <h4>Cabin Charges (Time: {stateData?.stateData?.removeHour}:{stateData?.stateData?.removeMin})</h4>
                <h4><i className="fa fa-rupee"></i>{stateData?.stateData?.cabin}</h4>
              </div>
            } */}
            <div className="invoiceData">
              <h4>Total Price</h4>
              <h4>
                <i className="fa fa-rupee"></i>
                {stateData?.stateData?.total_amount}
              </h4>
            </div>
            <div className="invoiceData">
              <h4>CGST (2.5%)</h4>
              <h4>
                <i className="fa fa-rupee"></i>
                {stateData?.stateData?.cgst}
              </h4>
            </div>
            <div className="invoiceData">
              <h4>SGST (2.5%)</h4>
              <h4>
                <i className="fa fa-rupee"></i>
                {stateData?.stateData?.sgst}
              </h4>
            </div>
            {/* {stateData?.stateData?.discountType?.length > 0 && (
              <div className="invoiceData">
                <h4>
                  {stateData?.stateData?.discountType?.length > 0 &&
                  stateData?.stateData?.discountType === "Flat"
                    ? "Flat Discount- "
                    : "Discount Percent- "}
                </h4>
                <h4>
                  {stateData?.stateData?.discountType === "Flat"
                    ? stateData?.stateData?.discountvalue
                    : `${stateData?.stateData?.discountPercent}%`}
                </h4>
              </div>
            )} */}
            <div className="invoiceData">
              <h2>Grand Total</h2>
              <h2>
                <i className="fa fa-rupee"></i>
                {stateData?.stateData?.grand_total?.toFixed()}
              </h2>
            </div>
          </div>
        </div>
        {/* <div className="invoiceQr">
          <QRCode
            className="invoiceQr"
            title="The BullBites Invoice"
            value={`upi://pay?pa=${upidata}&mc=your-merchant-code&tid=your-transaction-id&tr=your-transaction-reference-id&tn=Payment%20Description&am=${stateData?.stateData?.finalAmount}&cu=INR`}
          />
        </div> */}
        {/* <div className="invoiceQrtext">
          <h5>QR Code Scan & Pay</h5>
        </div> */}
        <div className="invoiceMobile">
          <h4>Mobile No. 9528979372</h4>
          <a href="https://www.bullbitescafe.com">
            <h4>www.bullbitescafe.com</h4>
          </a>
        </div>
        <div className="invoiceMobile">
          <h4>Date: {stateData?.stateData?.date}</h4>
        </div>
        <div className="invoiceLast">
          <h5>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            &nbsp;Pleasure Serving You&nbsp;
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
          </h5>
        </div>
        <div className="invoiceAllBtn">
          <div className="invoiceBtn">
            <button
              onClick={() => {
                window.print();
              }}
            >
              Print
            </button>
          </div>
          <div className="invoiceBtn">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              Go To Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

/* Invoice CSS */
