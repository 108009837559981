import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Fetch_Url } from "../../Context/Context";
// import QRCode from "react-qr-code";

export default function Invoice() {
  const location = useLocation();
  const stateData = location?.state;
  console.log(stateData, "stateData");

  const navigate = useNavigate();
  const [invoicedata, setInvoicedata] = useState([]);
  const [upidata, setUpidata] = useState([]);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const seconds = currentDate.getSeconds();

  const formattedDateTime = `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  } ${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }:${seconds < 10 ? "0" + seconds : seconds}`;
  // console.log("Date and Time:", formattedDateTime);

  const getUpi = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_fetch_upi",
      })
      .then((resp) => {
        console.log(resp, "resp getUpi data");
        setUpidata(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getInvoice = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_bill_print",
        invoice_id: stateData?.invoiceId,
      })
      .then((resp) => {
        console.log(resp, "resp getInvoice data");
        setInvoicedata(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getInvoiceNum = () => {
  //   axios
  //     .post(`${Fetch_Url}/restaurant_api.php`, {
  //       aksi: "proses_bill_print",
  //       invoice_id: stateData?.invoiceNum,
  //     })
  //     .then((resp) => {
  //       console.log(resp, "resp getInvoice data");
  //       setInvoicedata(resp?.data?.result);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    getInvoice();
  }, [stateData]);

  useEffect(() => {
    getUpi();
  }, []);

  return (
    <>
      <div className="invoiceCont">
        <div className="invoiceHead">
          <h4>The Bull Bites Cafe</h4>
          <h5>Sector-3, Herbal Park, New Moradabad</h5>
          <h5>
            <strong>Invoice No. {stateData?.invoiceId} </strong>
          </h5>
          <h6>GSTIN No. 09HOYPK7457G1ZW</h6>
        </div>
        <div className="invoiceBox">
          <div className="invoiceBot">
            <div className="invoiceData">
              <h4>Table </h4>
              <h4>{stateData?.stateData?.table_no}</h4>
            </div>
            <div className="invoiceData">
              <h4>Served By</h4>
              <h4>{stateData?.serviceMan}</h4>
            </div>
          </div>
          <hr />
          {invoicedata?.map((item) => {
            return (
              <>
                <div className="invoiceTxt">
                  <h5>{item?.item_name}</h5>
                  <h5>
                    {item?.quantity}
                    <i className="fa fa-times"></i>
                    {item?.current_price}
                  </h5>
                  <h5 style={{ textWrap: "nowrap" }}>
                    <i className="fa fa-rupee"></i> {item?.total_price}
                  </h5>
                </div>
              </>
            );
          })}
          <hr />
          <div className="invoiceBot">
            {/* {stateData?.cabin !== "" &&
              <div className="invoiceData">
                <h4>Cabin Charges (Time: {stateData?.removeHour}:{stateData?.removeMin})</h4>
                <h4><i className="fa fa-rupee"></i>{stateData?.cabin}</h4>
              </div>
            } */}
            <div className="invoiceData">
              <h4>Total Price</h4>
              <h4>
                <i className="fa fa-rupee"></i>
                {stateData?.totalPrice}
              </h4>
            </div>
            <div className="invoiceData">
              <h4>CGST (2.5%)</h4>
              <h4>
                <i className="fa fa-rupee"></i>
                {stateData?.cgst}
              </h4>
            </div>
            <div className="invoiceData">
              <h4>SGST (2.5%)</h4>
              <h4>
                <i className="fa fa-rupee"></i>
                {stateData?.sgst}
              </h4>
            </div>
            {stateData?.discountType?.length > 0 && (
              <div className="invoiceData">
                <h4>
                  {stateData?.discountType?.length > 0 &&
                  stateData?.discountType === "Flat"
                    ? "Flat Discount- "
                    : "Discount Percent- "}
                </h4>
                <h4>
                  {stateData?.discountType === "Flat"
                    ? stateData?.discountvalue
                    : `${stateData?.discountPercent}%`}
                </h4>
              </div>
            )}
            <div className="invoiceData">
              <h2>Grand Total</h2>
              <h2>
                <i className="fa fa-rupee"></i>
                {stateData?.finalAmount?.toFixed()}
              </h2>
            </div>
          </div>
        </div>
        {/* <div className="invoiceQr">
          <QRCode
            className="invoiceQr"
            title="The BullBites Invoice"
            value={`upi://pay?pa=${upidata}&mc=your-merchant-code&tid=your-transaction-id&tr=your-transaction-reference-id&tn=Payment%20Description&am=${stateData?.finalAmount}&cu=INR`}
          />
        </div> */}
        {/* <div className="invoiceQrtext">
          <h5>QR Code Scan & Pay</h5>
        </div> */}
        <div className="invoiceMobile">
          <h4>Mobile No. 9528979372</h4>
          <a href="https://www.bullbitescafe.com">
            <h4>www.bullbitescafe.com</h4>
          </a>
        </div>
        <div className="invoiceMobile">
          <h4>Date & Time: {formattedDateTime}</h4>
        </div>
        <div className="invoiceLast">
          <h5>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            &nbsp;Pleasure Serving You&nbsp;
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
          </h5>
        </div>
        <div className="invoiceAllBtn">
          <div className="invoiceBtn">
            <button
              onClick={() => {
                window.print();
              }}
            >
              Print
            </button>
          </div>
          <div className="invoiceBtn">
            <button
              onClick={() => {
                navigate("/");
              }}
            >
              Go To Dashboard
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

/* Invoice CSS */
